import React from "react"
import { Row, Col, Container } from "reactstrap"
import Image from "./image"
import { FaLinkedin } from "react-icons/fa"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="bg-primary text-light">
      <Container className="py-5">
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="1">
            <Image />
          </Col>
          <Col className="text-center">
            <h5 className="display-6 text-light">Regaining supply chain sovereignty.</h5>
          </Col>
          <Col lg="2" className="text-center text-lg-right mt-3 mt-mb-0">
            {/*<a href="https://www.facebook.com">
              <FaFacebook size="2.5em" className="mr-3 text-light" />
            </a>*/}
            <a href="https://www.linkedin.com/company/jrc-live/" className="text-light">
              <FaLinkedin size="2.5em" />
            </a>
          </Col>
        </Row>
        <Row className="mt-4 mt-md-5">
          <Col md="4">
            <p className="small font-weight-light">
              <strong>Acknowledgements:</strong> The financial support by the Austrian Federal Ministry for Digital and Economic 
              Affairs and the National Foundation for Research, Technology and Development is gratefully acknowledged.
            </p>

            <p>
              <Link to="/privacy-policy" className="text-white small">Privacy Policy</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer