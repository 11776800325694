import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container} from 'reactstrap'
import Logo from "../images/jrc-live-logo.png"
import { FaLinkedin } from 'react-icons/fa'

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar light expand="lg" className="bg-white shadow-sm">
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img src={Logo} className="img-fluid " alt={this.props.siteTitle} style={{maxWidth: '100px'}} />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar className="mx-auto">
              <NavItem>
                <NavLink to="/solutions/" tag={Link} activeClassName="active">
                  Solutions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/news/" tag={Link} activeClassName="active" partiallyActive={true}>
                  News
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/team/" tag={Link} activeClassName="active">
                  Team
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/partner/" tag={Link} activeClassName="active">
                  Partner
                </NavLink>
              </NavItem>
              {/*<NavItem>
                <NavLink to="/manual/" tag={Link} activeClassName="active">
                  Manual
                </NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink to="/contact/" tag={Link} activeClassName="active">
                  Contact
                </NavLink>
              </NavItem>
            </Nav>
            <Nav navbar>
              {/*<NavItem>
                <NavLink href="https://www.facebook.com">
                  <FaFacebook />
                </NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink href="https://www.linkedin.com/company/jrc-live/">
                  <FaLinkedin />
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
