/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.scss"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        location="bottom"
        containerClasses="border-top bg-primary"
        buttonClasses="btn btn-outline-light mr-1"
        disableButtonStyles
      >
        Our website uses cookies to improve your experience.{" "}
        By continuing without changing your cookie settings, we assume you agree to this.{" "}
        See our <Link to="/privacy-policy" className="text-white">privacy policy</Link> for further details.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
